.drower__modal-btn {
  background: #ff4d4f;
  color: #fff;
  border-radius: 5px;
  border-color: #ff4d4f;
  padding: 2px 10px;
  margin: 0 5px 0 0;
  font-size: 16px !important;
  height: max-content;
  cursor: pointer;
}
/* telegrem button color #3476ab */

.drower-open-modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  transition: 0.3s all;
  z-index: 1001;
  overflow: auto;
  padding-top: 10px;
  opacity: 0;
}
.drower-open-modal.actives {
  opacity: 1;
  pointer-events: all;
}
.drower-modal-content {
  height: max-content;
  margin-top: 10px;
  padding: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1002;
  position: absolute;
  top: 0;
  right: -150%;
  background: #fff;
}
.drower-modal-content.activ {
  right: 0;
  transition: 0.4s all;
}
