.edit__modal-btn {
  background: #ff4d4f;
  color: #fff;
  border-radius: 5px;
  border-color: #ff4d4f;
  padding: 2px 10px;
  margin: 0 5px 0 0;
  font-size: 16px !important;
  height: max-content;
  cursor: pointer;
}
/* telegrem button color #3476ab */

.edit-open-modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 10;
  overflow-y: scroll;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  padding: 50px 0;
}
.edit-open-modal.active {
  opacity: 1;
  pointer-events: all;
}
.edit-modal-content {
  min-width: 60vw;
  min-height: 50vh;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  transition: 0.4s all;
  z-index: 11;
}
