@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");
.login-form {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../img/pel2.jpg) no-repeat;
  background-size: cover;
  background-position: bottom;
}

.login__mini-block {
  padding: 50px 20px;
  min-width: 30%;
  min-height: 40%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background-color: #ffffff4d;
  box-shadow: 0px 0px 12px -1px #e7e7e770;
  border: 1px solid #fff3;
}
.login__mini-block > span {
  font-family: "Dancing Script", cursive !important;
  font-size: 150px;
  color: #0f9c54;
  text-shadow: rgb(92, 31, 0) 0 0 1px, rgb(82, 27, 0) -1px 1px 0,
    rgb(71, 24, 0) -2px 2px 2px, -4px 4px 2px rgb(61, 20, 0),
    -6px 6px 3px rgb(51, 17, 0), -8px 8px 2px rgb(46, 15, 0),
    -10px 10px 3px rgb(41, 14, 0), -12px 12px 2px rgb(36, 12, 0),
    -14px 14px 2px rgb(31, 10, 0), -15px 15px 2px rgb(26, 9, 0),
    -15px 15px 0 rgba(38, 21, 13, 0.25);
}
.login-form-nput {
  border: none;
  outline: none;
  font-size: 26px;
  padding: 5px 15px;
  margin: 10px;
  border-radius: 8px;
  outline: none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

::placeholder {
  border-color: #1890ff;
  text-transform: uppercase;
  font-size: 16px;
}
.login-form-btn {
  font-family: "Dancing Script", cursive !important;
  outline: none;
  border: none;
  margin-top: 10px;
  background-color: transparent;
  cursor: pointer;
  color: #0f9c54;
  font-size: 45px;
  text-shadow: rgb(92, 31, 0) 0 0 1px, rgb(82, 27, 0) -1px 1px 0,
    rgb(71, 24, 0) -2px 2px 2px, -4px 4px 2px rgb(61, 20, 0),
    -6px 6px 3px rgb(51, 17, 0), -8px 8px 2px rgb(46, 15, 0),
    -10px 10px 3px rgb(41, 14, 0);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.login-form-btn:hover {
  color: #fff;
}

/* .login__mini-block-img {
  transform: translateY(7px);
} */

@media (min-width: 320px) and (max-width: 1124px) {
  .login__mini-block {
    width: max-content;
    height: max-content;
    padding: 10px;
  }
  .login__mini-block-h2 {
    font-size: 12px;
    padding: 0;
    margin: 0;
  }
}

/* @supports (-webkit-text-stroke: 1px white) {
  .login__mini-block > span {
    text-shadow: none;
    color: transparent;
    -webkit-text-stroke: 1px #fff;
  }
} */
