.OrdersTableDate {
  display: flex;
  justify-content: space-between;
}
.OrdersDetailedTable {
  width: 100%;
  border-collapse: collapse;
}
.OrdersDetailedTable thead tr th {
  background: #f4f4f4 !important;
}
.OrdersDetailedTablePrint {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}
.OrdersDetailedTr {
  border-bottom: 1px solid rgb(181, 181, 181) !important;
}
.OrdersDetailedTr:nth-child(even) {
  background: #f4f4f4 !important;
}

.OrdersDetailedTd {
  text-align: center;
  padding: 10px 0;
}
.OrdersDetailedTd:nth-child(1) {
  width: 50px;
}

/* confirm page */
.BlockAmallarConfirms {
  display: flex;
  justify-content: space-around;
}

.BlockTableBrint {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
}
.BlockTableImgBlock {
  height: 120px;
  display: flex;
  justify-content: center;
}
.BlockTableImg {
  width: 200px;
  margin: 0;
}
@media print {
  .OrdersDetailedTable thead tr th:nth-child(6) {
    display: none;
  }
  .OrdersDetailedTd:nth-child(6) {
    display: none;
  }
}
