.sklad__wrapper {
  background: #f9f9f9;
  min-height: 100vh;
}
.sklad__header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #008080;
}
.headet__user-name {
  font-size: 24px;
  color: #fff;
}
.header__navigate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.header__navigare-list {
  margin: 0;
  display: flex;
}
.header__navigare-list > li {
  list-style: none;
  margin: 0 20px;
  padding: 10px;
}
.navigation-Item {
  text-decoration: none;
  font-size: 20px;
  color: #fff;
}
.navigation-Item:hover {
  border-bottom: 1px solid #fff;
}
.navigation-Item-Active {
  text-decoration: none;
  font-size: 20px;
  color: tomato;
}

.sklad__content {
  min-height: calc(100vh - 63px);
  margin: 0 auto;
  padding: 30px 50px;
  background: #fff;
}
