@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
.ant-table-summary {
  background: rgb(228, 228, 228) !important;
}
* {
  font-family: "Nunito", sans-serif !important;
  /* font-size: 17px !important; */
  box-sizing: border-box;
}
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.header__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ant-table-row.ant-table-row-level-0 {
  padding: 0 !important;
  margin: 0 !important;
}

/* .filter-block {
  height: 80vh;
  overflow-y: auto;
} */
@media print {
  html,
  body {
    height: auto;
    margin: 0 !important;
    padding: 0 !important;
    /* overflow: auto; */
  }
  .orders__new-amallar {
    display: none;
  }
}

.code-box-demo .demo {
  overflow: auto;
}
.code-box-demo .ant-btn {
  margin-right: 8px;
  margin-bottom: 8px;
}
.code-box-demo .ant-btn-rtl {
  margin-right: 0;
  margin-left: 8px;
  margin-bottom: 8px;
}
#components-popover-demo-placement .ant-btn {
  width: 70px;
  text-align: center;
  padding: 0;
}

/* post  */
/*  */
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.ant-list-item:hover {
  background-color: #f6f6f6;
}
.ant-empty-description {
  display: none !important;
}
.ant-list-item::marker {
  content: "";
}
.ant-table-cell {
  vertical-align: top;
}
