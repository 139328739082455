.EditWorkingContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 20px;
}

.clientAddInp {
  width: 100%;
  margin: 10px 5px;
  padding: 10px;
  font-size: 22px;
  border: none;
  background: #969595;
  color: black;
  border-radius: 5px;
}

.clientAddInp:focus {
  /* border: 1px solid red; */
  border: none;
  outline: none;
  background: #fff;
  box-shadow: 0px 0px 5px -1px red;
}
.StyleBtnAdd {
  background: #969595;
  color: #fff;
}
.StyleBtnAdd:hover {
  background: brown;
  border-color: brown;
}

/* users */

/*      */

.WorkerTable {
  width: 100%;
}
.WorkerTableTh {
  /* background-color: #8c8c8c; */
  background-color: #bfbfbf;
  color: #fff;
  font-size: 20px;
}
.WorkerTableTh th {
  padding: 5px 0;
  text-align: start;
  padding-left: 20px;
}
.WorkerTableTd {
  text-align: center;
  border-bottom: 1px solid #d9d9d9;
}
.WorkerTableTd td {
  text-align: start;
  padding-left: 20px;
  font-size: 18px;
}
.WorkerTableTd:hover {
  background: #4a4949;
  color: #fff;

  /* transform: translate(-10px, -10px) scale(1); */
  /* transition: 0.3s; */
}
.WorkerTableTd:hover .WorkerTableTdata {
  color: #fff !important;
}
.WorkerTableTdata {
  padding: 5px 0;
  border-bottom: 1px solid #d9d9d9;
  font-size: 18px;
  color: black;
}
.WorkerTableTd td:nth-child(1) {
  /* border-bottom: 1px solid #d9d9d9; */
  color: #ff4d4f;
}
.WorkerTableTButton {
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
}
.Workerbutton {
  background: #ff4d4f;
  color: #fff;
  border-radius: 5px;
  border-color: #ff4d4f;
  padding: 2px 10px;
  margin: 0 5px 0 0;
  font-size: 16px !important;
  height: max-content;
}
@media (min-width: 320px) and (max-width: 1124px) {
  .WorkerTableBlock {
    overflow-x: scroll;
  }
  .WorkerTable {
    overflow-x: scroll;
  }
  .login__mini-block-h2 {
    font-size: 8px !important;
  }
}

.UserNewLoding {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
