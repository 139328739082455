.ActSwerkiTable {
  font-size: 18px !important;
}
.ActSwerkiTableSummary {
  font-size: 18px !important;
}
.ActSwerkiTableSummary span {
  font-size: 18px !important;
}
.ActSwerkiTableHeaderInfo {
  display: flex;
  justify-content: space-between;
}
@media print {
  col {
    width: 120px !important;
  }
  col:nth-child(1) {
    width: 100px;
  }
  .ActSwerkiTableHeader {
    display: none !important;
  }
  .ActSwerkiTable {
    font-size: 16px !important;
  }
  .ActSwerkiTableSummary {
    font-size: 17px !important;
  }
  .ActSwerkiTableSummary span {
    font-size: 17px !important;
  }
}
