.blockDaiyliHeader {
  display: flex;
  justify-content: space-between;
}
.dailyTable {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-collapse: collapse;
  /* border: 1px solid hsla(0, 0%, 0%, 0.2); */
}
.dayliTableTh {
  text-align: start;
  font-size: 20px;
  padding: 5px 0px 5px 10px;
  background: #f4f4f4 !important;
}
.dayliTableTr {
}
.dayliTableTr:nth-child(even) {
  background: #f4f4f4 !important;
}
.dayliTableTd {
  padding: 10px 0px 10px 10px;
  font-size: 18px;
}

.OrdersDailyTable2Header {
  display: flex;
}
.OrdersDailyTable2Header2 {
  padding: 20px;
  border-radius: 10px;
  background: #f4e2e2;
  margin-bottom: 10px;
}
.OrdersDailyTable2Header2:nth-child(2) {
  margin-left: 15px;
}
